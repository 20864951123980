<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card>
      <div class="mb-3 w-100 d-flex align-items-center">
        <h1>Pages SEO Setting</h1>
      </div>
      <b-row>
        <b-col cols="12">
          <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="pageList"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="No items available"
          >
            <template #cell(createDate)="data">
              {{ new Date(data.item.createDate).toLocaleDateString("fa-IR") }}
            </template>
            <template #cell(pageShapeType)="data">
              {{ pageShapeTypes[data.item.pageShapeType] }}
            </template>
            <template #cell(link)="data">
              <template v-if="!data.item.isStatic">
                {{ data.item.link ? data.item.link.split("/pages/")[1] : "#" }}
              </template>
              <template v-else-if="data.item.isStatic">
                {{ data.item.link }}
              </template>
            </template>
            <template #cell(actions)="data">
              <div class="d-flex align-items-start" style="gap: 2rem">
                <b-link
                  :to="{
                    name: 'pages-seo-pages-single',
                    params: { id: data.item.pageId },
                  }"
                >
                  <feather-icon
                    size="20"
                    icon="SettingsIcon"
                    class="text-primary"
                  />
                </b-link>
              </div>
            </template>
          </b-table>
        </b-col>
        <b-col cols="12">
          <!-- pagination -->
          <div class="my-2 d-flex align-items-center justify-content-center">
            <b-pagination
              v-model="pageNumber"
              :total-rows="totalCount"
              :per-page="count"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>
<script>
export default {
  title: "Pages SEO Setting",
  mounted() {
    this.getAllPages();
  },
  watch: {
    pageNumber: {
      handler(val) {
        this.getAllPages();
      },
    },
  },
  data() {
    return {
      isLoading: false,
      count: 10,
      pageNumber: 1,
      totalCount: null,
      pageList: null,
      myTableColumns: [
        {
          key: "pageId",
          label: "Page ID",
        },
        {
          key: "title",
          label: "Page Title",
        },
        {
          key: "link",
          label: "Page Link",
        },
        {
          key: "pageShapeType",
          label: "Page Type",
        },
        {
          key: "createDate",
          label: "Create Date",
        },
        {
          key: "actions",
          label: "Set SEO Settings",
        },
      ],
      pageShapeTypes: {
        1: "Homepage",
        2: "Blog",
        3: "Contact Us",
        4: "Representative",
        5: "Service",
        6: "Dynamic",
      },
      tempPage: null,
      langsObject: null,
    };
  },
  watch: {
    pageNumber: {
      handler(val) {
        this.getAllPages();
      },
    },
  },
  methods: {
    async getAllPages() {
      try {
        this.isLoading = true;
        let _this = this;
        let getAllPages = new GetAllPages(_this);
        getAllPages.setRequestParam({
          count: _this.count,
          pageNumber: _this.pageNumber,
        });
        await getAllPages.fetch((response) => {
          if (response.isSuccess) {
            _this.totalCount = response.data.formsCount;
            _this.pageList = response.data.forms;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BContainer,
    BPagination,
    BFormRow,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BFormTextarea,
    BOverlay,
    BFormSelect,
  },
};
import { GetAllPages } from "@/libs/Api/Page";
import {
  BContainer,
  BPagination,
  BFormRow,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BTable,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormTextarea,
  BOverlay,
  BFormSelect,
} from "bootstrap-vue";
</script>
